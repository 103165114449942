import {
  useMemo, useState, useEffect, useRef,
} from 'react';
import AngleUp from '../Icons/IconAngleUp'

export const Pagination = ({
  RenderComponent,
  data,
  pageLimit,
  dataLimit,
  showPerPg,
  passclass,
  ...rest
}) => {
  const [pageSize, setPageSize] = useState(dataLimit);
  const [pages, setPages] = useState(Math.ceil(data.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);
  const [pgslice, getpgslice] = useState([]);
  const isMountedRef = useRef(null);

  useEffect(() => {
    setPages(Math.ceil(data.length / dataLimit));
    setCurrentPage(1);
  },[data, dataLimit])
  

  useEffect(() => {
    isMountedRef.current = true;
    if (isMountedRef.current) {
      const startIndex = currentPage * dataLimit - dataLimit;
      const endIndex = startIndex + dataLimit;
      getpgslice(data.slice(startIndex, endIndex))
    }
    return () => isMountedRef.current = false;
  }, [data, currentPage, dataLimit])

  function nextPage() {
    setCurrentPage((page) => page + 1);
  }

  function previousPage() {
    setCurrentPage((page) => page - 1);
  }

  const mappedInventory = useMemo(
    () =>
    {return (
        <>
          {pgslice.length !== 0 ? pgslice.map((d, idx) => <RenderComponent key={idx} data={d} product={d} {...rest} />) : <div className="align-center justify-center h-40">No reviews yet</div>}
        </>
      )},
    [pgslice, currentPage],
  )
  return (
    <>
      <div className={`dataContainer ${passclass}`}>
        {mappedInventory}
      </div>
      <div className="s-sm" />
      <div className="pagination">
        <div className="align-center flex-col">
          <div className="pagholder justify-around align-center btn-pill body-sm ">
            <button onClick={() => previousPage()} disabled={currentPage === 1}><AngleUp /></button>
            {' '}
            <span>
              {currentPage}
              {' '}
              of
              {' '}
              {pages || 1}
            </span>
            <button onClick={() => nextPage()} disabled={currentPage === pages}>
              <AngleUp />
              {' '}
            </button>
          </div>
          {showPerPg
          && (
          <>
            <div className="s-sm" />
            <div className="s-sm" />
            <div className="pageview">
              <div>VIEW PER PG </div>
              {[5, 10, 25].map((pageSizez) => (
                <span onClick={(e) => { setPageSize(Number(pageSizez)); }} key={pageSizez}>
                  {pageSizez === pageSize ? <span className="cprimary">{pageSizez}</span> : pageSizez}
                </span>
              ))}
            </div>
          </>
          )}
        </div>
      </div>
    </>
  );
}

