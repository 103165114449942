import { useState, useRef, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useCachedValueOnLocationChange = () => {
  const location = useLocation(); // Hook to get the current location
  const [value, setValue] = useState(''); // State to store the value
  const cachedValue = useRef(null); // Ref to cache the value

  const getApiPath = useMemo(() => {
    const loc = location.pathname.split('/')[1];
    if (cachedValue.current !== loc) {
      // Perform your logic to compute the new value based on location
      if (loc === 'direct' || loc === 'sa') {
        return '/sa'
       // setValue('/sa');
      } else {
        return ''
      //  setValue('');
      }
    //  console.log('path is: ', loc)
    }
    }, [location.pathname]);


  return getApiPath // value; // Return the value (state)
};
