import Rate from 'rc-rate';
import { useState, useEffect, useRef } from 'react';
import { Hearts } from 'react-loader-spinner';
import { useParams, Link, useLocation } from 'react-router-dom';
import Instagram from '../Icons/IconInstagram';
import DownArrow from '../Icons/IconDownArrow';
import Star from '../Icons/IconStar';
import { useShop } from '../../Context/ShopContext';
import { InstagramLink } from './InstagramLink';
import { Pagination } from '../Common/pagination';
import ReactSelect from 'react-select';
import { DropdownIndicator, customStyles } from '../Seller/ShipEngine/customStyles1';

const orderKeys = [
  { value: 1, label: 'By Date' },
  { value: 2, label: 'Highest Rating' },
  { value: 3, label: 'Lowest Rating' },
];

const SellerAvatar = ({ sellerInfo }) => (
  <div className="avitar">
    <div className="imgholder">
      {sellerInfo.sellerav === undefined
        ? <div className='avita-imageholder'>{sellerInfo.name.charAt(0)}</div>
        : <img alt="profile" src={sellerInfo.sellerav.location} />}
    </div>
    <div className="justify-around align-start flex-col h-100">
      <h1>{sellerInfo.name}</h1>
      <div className="redtext flex justify-start">
        <span className="iconpadder align-center justify-center"><Instagram /></span>
        <InstagramLink addclass='small redtext body-sm h5' ighandle={sellerInfo.instagram} />
      </div>
    </div>
  </div>
);

const SellerFeedbackRow = ({ data }) => (
  <div>
    <div className='h-4' />
    {typeof (data) === 'object' ? (
      <div>
        <div className='reviews-header'>
          <div className='reviews-header-left'>
            <img src={data.img} alt="image" width="32px" style={{ marginTop: 12 }} />
            <div className='name-review'>
              <Rate
                value={data.rate}
                style={{ fontSize: 18, color: '#F05F40' }}
                allowHalf
                disabled
              />
              <div className='name-review-name h6'>{data.name ? data.name : '---------------'}</div>
            </div>
          </div>
          <div className='reviews-header-right lightgrey h6-cond-med'>
            {data.date.substring(5, 7)}/{data.date.substring(8, 10)}/{data.date.substring(2, 4)}
          </div>
        </div>
        <div className='lightgrey h6' style={{ marginTop: '7px', lineHeight: '20px' }}>{data.review}</div>
        <div className='h-12' />
      </div>
    ) : (
      <div>
        <div className='lightgrey h6'>{data}</div>
        <div className='h-12' />
      </div>
    )}
    <div className='greyline' />
  </div>
);

const SellerReviews = () => {
  const location = useLocation();
  console.log(location.state)
  const { data } = location.state;
 
  const [products, setProducts] = useState(null);
  const [feedback, setFeedback] = useState([]);
  const { fetchsellerProducts, sellerInfo } = useShop();
  const [selectedOption, setSelectedOption] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(25);
  const isMountedRef = useRef(null);
  const params = useParams();

  const fetchData = async (id) => {
    const  items  = await fetchsellerProducts(id);
    const prodcomp = items.map(product => {
      let hasinvz = 'sold-out';
      if (product.sizes) {
        product.sizes.forEach(size => {
          if (product.totalAllowed > 0 && size.qty > 0) {
            hasinvz = 'has-inv';
          }
        });
      }
      return { ...product, hasInvText: hasinvz };
    });
    setProducts(prodcomp);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const data_origin = data.feedback.filter(review => review !== '');
    const temp = data_origin.map(item => item.includes('https://') ? JSON.parse(item) : item);
    const initialSortedData = temp.sort((a, b) => {
      if (!b['date']) return -1;
      if (!a['date']) return 1;
      return b['date'] < a['date'] ? -1 : 1;
    });
    setFeedback(initialSortedData);
    isMountedRef.current = true;
    if (isMountedRef.current) {
      fetchData(params.username);
    }
    return () => isMountedRef.current = false;
  }, []);

  const setOption = (option) => {
    setSelectedOption(option);
    const sortedData = [...feedback];
    if (option === 1) {
      sortedData.sort((a, b) => {
        if (!b['date']) return -1;
        if (!a['date']) return 1;
        return b['date'] < a['date'] ? -1 : 1;
      });
    } else if (option === 2) {
      sortedData.sort((a, b) => b['rate'] - a['rate']);
    } else if (option === 3) {
      sortedData.sort((a, b) => a['rate'] - b['rate']);
    }
    setFeedback(sortedData);
  };

  if (products === null || sellerInfo === null) {
    return (
      <div className="overlay">
        <Hearts color="#F05F40" height={100} width={100} />
      </div>
    );
  }

  return (
    <div className="site-contain">
      <div className='dashboard_products_wrapper inventory_wrapper seller-products'>
        <SellerAvatar sellerInfo={sellerInfo} />
        <div id="stat-section">
          <div className='h-24' />
          <div className="greyline" />
          <div className='h-16' />
          <div className="adjust-center flex align-center justify-center body-sm">
            <Link to={`/shop/profile/${sellerInfo.username}`}>
              <div className="blacklink align-center w-100 pointer">
                <DownArrow style={{ height: '8px' }} className="icon-auto-widtha rotate-90 greystroke" />
                <div>Profile</div>
              </div>
            </Link>
            <div className="text-center justify-center">
              {feedback?.length}&nbsp; <div>Review{feedback?.length > 1 && 's'}</div>
            </div>
            <div className="rating-right justify-end">
              {sellerInfo.rating && (
                <>
                  <Star className="" style={{ marginRight: '3px', height: '21px' }} />{sellerInfo.rating.toFixed(1)}
                </>
              )}
            </div>
          </div>
          <div className='h-16 greyline' />
          <div className='h-48' />
        </div>
        <div className='seller-review-all'>
          <h3>Reviews &nbsp;</h3>
          <ReactSelect
            components={{ DropdownIndicator }}
            styles={customStyles}
            classNamePrefix="react-select"
            className="required selectField"
            options={orderKeys}
            isClearable
            value={orderKeys[selectedOption - 1]}
            onChange={(e) => setOption(e.value)}
          />
        </div>
        <div className='h-24' />
        <div className='greyline' />
        <Pagination
          showPerPg={false}
          pageLimit={5}
          dataLimit={numberPerPage}
          data={[...feedback]}
          RenderComponent={SellerFeedbackRow}
        />
        <div className='view-per-page-wrapper'>
          <div className='view-per-page'>
            <div className='lightgrey'>View Per Page</div>
            <div onClick={() => setNumberPerPage(25)} className={numberPerPage !== 25 ? 'lightgrey' : ''}>25</div>
            <div onClick={() => setNumberPerPage(50)} className={numberPerPage !== 50 ? 'lightgrey' : ''}>50</div>
            <div onClick={() => setNumberPerPage(75)} className={numberPerPage !== 75 ? 'lightgrey' : ''}>75</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerReviews;