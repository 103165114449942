const SvgIconInstagram = (props) => (
  <svg viewBox="0 0 12 13" {...props}>
    <defs>
      <path id="icon-instagram_svg__a" d="M0 .004h11.996V12H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 .02)">
        <mask id="icon-instagram_svg__b" fill="#fff">
          <use xlinkHref="#icon-instagram_svg__a" />
        </mask>
        <path
          d="M5.998.004C4.37.004 4.165.01 3.525.04 2.887.069 2.451.17 2.07.319a2.94 2.94 0 00-1.062.692 2.94 2.94 0 00-.692 1.062c-.148.381-.25.817-.279 1.456-.03.64-.036.844-.036 2.473s.007 1.833.036 2.473c.03.638.13 1.074.279 1.456.153.394.358.729.692 1.062a2.94 2.94 0 001.062.692c.382.148.818.25 1.456.279.64.03.844.036 2.473.036 1.63 0 1.833-.007 2.473-.036.639-.03 1.075-.13 1.456-.279a2.94 2.94 0 001.062-.692 2.94 2.94 0 00.692-1.062c.149-.382.25-.818.28-1.456.028-.64.035-.844.035-2.473 0-1.63-.007-1.833-.036-2.473-.029-.639-.13-1.075-.279-1.456a2.94 2.94 0 00-.692-1.062A2.94 2.94 0 009.927.319C9.546.17 9.11.069 8.471.039 7.831.012 7.627.005 5.998.005m0 1.08c1.602 0 1.791.007 2.424.035.585.027.902.125 1.114.207.28.109.48.239.69.449.21.21.34.41.448.69.082.21.18.528.207 1.113.028.633.034.822.034 2.424 0 1.601-.006 1.791-.034 2.424-.027.584-.125.902-.207 1.113-.109.28-.239.48-.449.69-.21.21-.41.34-.69.449-.21.082-.528.18-1.113.206-.633.03-.822.035-2.424.035-1.602 0-1.791-.006-2.424-.035-.584-.026-.902-.124-1.113-.206a1.858 1.858 0 01-.69-.449c-.21-.21-.34-.41-.449-.69-.082-.21-.18-.529-.206-1.113-.03-.633-.035-.823-.035-2.424 0-1.602.006-1.791.035-2.424.026-.585.124-.902.206-1.114.109-.28.239-.48.449-.69.21-.21.41-.34.69-.448.21-.082.529-.18 1.113-.207.633-.028.823-.034 2.424-.034"
          fill="#000"
          mask="url(#icon-instagram_svg__b)"
        />
      </g>
      <path
        d="M5.998 8.021a2 2 0 110-3.999 2 2 0 010 4m0-5.08a3.08 3.08 0 100 6.16 3.08 3.08 0 000-6.16M9.92 2.82a.72.72 0 11-1.44 0 .72.72 0 011.44 0"
        fill="#000"
      />
    </g>
  </svg>
);

export default SvgIconInstagram;
