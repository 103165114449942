import { isMobile } from 'react-device-detect';

export function InstagramLink({ addclass, ighandle }) {
  return (<>
    {isMobile ? <a className={addclass} href={`instagram://user?username=${ighandle}`} rel="noreferrer" target="_blank">
      {' '}
      {ighandle}
      {' '}
    </a> : <a href={`https://instagram.com/_u/${ighandle}`} rel="noreferrer" className={addclass} target="_blank">
      {' '}
        {ighandle}
      {' '}
    </a>}
  </>);
}
