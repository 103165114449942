import axios from 'axios';

const customAxios = axios.create()
customAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;
  if (config.timeout === undefined || config.timeout === 0) {
    return config;
  }
  config.params = {
    timeout: 30000,
    ...config.params,
  };
  const source = axios.CancelToken.source();

  setTimeout(() => {
    source.cancel(`Cancelled request. Took longer than ${config.timeout}ms to get complete response.`);
  }, config.timeout);

  // If caller configures cancelToken, preserve cancelToken behaviour.
  if (config.cancelToken) {
    config.cancelToken.promise.then((cancel) => {
      source.cancel(cancel.message);
    });
  }

  return { ...config, cancelToken: source.token };
}, (error) => Promise.reject(error));

export default customAxios
