const SvgIconGreyX = (props) => (
  <svg width="14" height="13" viewBox="0 0 14 13" {...props}>
    <path d="M12.0141 11.5142L1.7563 1.25644" stroke="#C3C3C3" strokeWidth="1.28" strokeLinecap="square" />
    <path d="M2.1709 11.5658L12.4287 1.30806" stroke="#C3C3C3" strokeWidth="1.28" strokeLinecap="square" />

  </svg>
);

export default SvgIconGreyX;

// const SvgIconGreyX = props => (
//   <svg width="14" height="14" viewBox="0 0 14 14" {...props}>
//     <path d="M1 1L13 13" stroke="#686868" />
//     <path d="M13 1L1 13" stroke="#686868" />

//   </svg>
// );

// export default SvgIconGreyX;
