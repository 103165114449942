// React imports
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import {StripeProvider} from "react-stripe-elements"

// Component imports
// import 'react-toggle/style.css';
import './index.scss';
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>, // </StripeProvider>

  document.getElementById('root'),
);
