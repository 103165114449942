import { Suspense, lazy } from 'react';
import { Hearts } from 'react-loader-spinner'
import { Route, Routes } from 'react-router-dom';

import { ShopProvider } from './Context/ShopContext';
// import { useLogin } from './Context/LoginContext';
// import { SubscriptionStatusProvider } from './Context/SubscriptionStatusContext';
import SubscriptionProtectedRoutes from './SubscriptionProtectedRoutes';
// import { useSubscriptionStatus } from './Context/SubscriptionStatusContext';
const PurchaseConfirm = lazy(() => import(/* webpackChunkName: 'Checkout' */ './components/Item/PurchaseConfirm'));
const Item = lazy(() => import(/* webpackChunkName: 'Checkout' */ './components/Item/Item'));
//const BuyerProducts = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/BuyerProducts'));
const Shop = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/ShopLanding'));
const SellerProducts = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/SellerProducts'));
const SellerProfile = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/SellerProfile'));
const Notifications = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/Notifications/Notifications'));
const SellerReviews = lazy(() => import(/* webpackChunkName: 'Shop' */'./components/Buyer/SellerReviews'));
const ProtectedRoute = lazy(() => import(/* webpackChunkName: 'Shop' */ './ProtectedRoute'));

const BuyerProfile = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Buyer/Profile'));
const SendConfirmation = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Buyer/Confirmation/ShipConfirmation'));
const SAConfirmation = lazy(() => import(/* webpackChunkName: 'Buyer' */ './components/Buyer/Confirmation/SAConfirmation'));
const SubscribePage = lazy(() => import(/* webpackChunkName: 'Subscribe' */ './components/Buyer/Subscription/SubscribePage'));

const Page404 = lazy(() => import('./components/404/404'));

const LoadingIndicator = () => (
  <div className="overlay">
    <Hearts color="#F05F40" height={100} width={100} />
  </div>
);

const ShopLayout = ({ children }) => { 
  // const { token,  buyer,  } = useLogin();
  // const { loading, fetchSubscriptionStatus  } = useSubscriptionStatus();
  // useEffect(() => {
  //   (buyer && token) && fetchSubscriptionStatus();
  // }, []);
  
  // if (loading) {
  //   return <LoadingIndicator />;
  // }
 
  return (
    <ShopProvider>
      <Suspense fallback={<LoadingIndicator />}>
        <Routes>
          {/* <Route path='/' element={<SignUp />} /> */}
          <Route path="/direct/*" element={<SubscriptionProtectedRoutes />} />
          <Route path="/confirm-order/:stoken" element={<SendConfirmation />} />
          <Route path="/bd/confirm-order/:stoken" element={<SAConfirmation />} />
          <Route path="/confirm" element={<PurchaseConfirm />} />
          <Route path="/product/:_id" element={<Item />} />
          <Route path="/" element={<Shop />} />

          <Route path="/shop">
            <Route index element={<Shop />} />
            <Route path=":username" element={<SellerProducts />} />
            <Route path="profile/:username" element={<SellerProfile />} />
            <Route path="profile/:username/review" element={<SellerReviews />} />
          </Route>

          {/* Protected routes for Buyer */}
          <Route path="/shop/" element={<ProtectedRoute />}>
             {/* possibly move to protected section.... */}
            <Route path='customerprofile' element={(<BuyerProfile />)} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="subscribe" element={<SubscribePage />} />
            
            {/* <Route path='history' element={<OrdersProvider><SalesTracker /></OrdersProvider>} /> */}
          </Route>

          {/* Catch-all route for 404 */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </ShopProvider>
)};

export default ShopLayout;

      
//       function SALayout() {
//       return (
//         <>
//    {/* Protected routes for customers with subscription */}
//           <Route element={<ProtectedRouteSA/>} >
//             <SubscriptionStatusProvider>
//               <Routes>
//                 <Route path="/listings" element={<SaAllProducts />} />
//                 <Route path="/listings/:username" element={<SaIndProducts />} />

//                 <Route path="/listings/product/:_id" element={<Item />} />
//               </Routes>
//               <Outlet />
//             </SubscriptionStatusProvider>
       
//         </Route>

// </>
//       );
//     }
    