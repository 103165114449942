// ShopperRoutes.js
import { Routes , Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { Hearts } from 'react-loader-spinner';
const SellerLogin = lazy(() => import(/* webpackChunkName: 'Seller' */'./components/Seller/Login/SellerLogin'));
const SellerForgot = lazy(() => import(/* webpackChunkName: 'Seller' */'./components/Seller/Login/SellerForgot'));
const SellerReset = lazy(() => import(/* webpackChunkName: 'Seller' */'./components/Seller/Login/SellerReset'));
const SellerResetConfirm = lazy(() => import(/* webpackChunkName: 'Seller' */'./components/Seller/Login/SellerResetConfirm'));
const SellerSignUpflow = lazy(() => import(/* webpackChunkName: 'Seller' */'./components/Seller/SellerSignUp/Sellersignupflow'));
// const SellerConf = lazy(() => import('./components/Seller/SellerSignUp/SellerConf'));
const SellerApply = lazy(() => import(/* webpackChunkName: 'Seller' */'./components/Seller/SellerSignUp/SellerApply'));
const ShopperLayout = lazy(() => import('./ShopperMainLayout'));

const LoadingIndicator = () => (
  <div className="overlay">
    <Hearts color="#F05F40" height={100} width={100} />
  </div>
);

const ShopperRoutes = () => {

  // const { setPath } = useLogin()
  // // This sets the toggle value if the path to the backend api will be amended to point to the 
  // // Sales Assoiate functions while still using the same front end code for getters/setters. :)
  // useEffect(() => {
  //   setPath({ isPath: '' });
  //   // console.log('SA')
  // }, []);

  return (
       <Suspense fallback={<LoadingIndicator />}>
      <Routes>
      <Route path='/signup/:stoken' element={<SellerSignUpflow />} />
      <Route path='/apply' element={<SellerApply />} />
      <Route path='/sellerLogin' element={<SellerLogin headerText="Seller Login" />} />
      <Route path='/sellerForgot' element={<SellerForgot />} />
      <Route path='/sellerReset/:resettoken' element={<SellerReset />} />
      <Route path='/sellerResetConfirm/' element={<SellerResetConfirm />} />
      <Route path='/*' element={<ShopperLayout />} />
    </Routes>
    </Suspense>
  );
};

export default ShopperRoutes;