import { components } from 'react-select';
// import DownArrow from '../../Icons/IconDownArrow'
import IconSort from '../../Icons/IconSort';
import GreyX from '../../Icons/IconGreyX'

const customStyles = {
  indicatorSeparator: () => ({
    	marginBottom: '16px',
	    marginTop: '17px',
    //  display: 'none'
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: '#C3C3C3',
  }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    borderWidth: 0,
    // borderBottom: 'none',
    color: state.isSelected ? '#f05f40' : 'black',
    backgroundColor: state.isFocused ? '#d6d6d6' : null,
    // backgroundColor: state.isSelected ? 'red' : 'white'
  }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    borderRadius: 0,
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 0,
    zIndex: 2,
  }),
  indicatorContainer: () => ({
    color: 'black',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingLeft: '24px',
  }),
  valueContainer: (base) => ({
    ...base,
    minHeight: '69px',
    backgroundColor: 'transparent',
  }),
  control: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    minHeight: '69px',
    border: '0',
    // placeholder: "#C3C3C3",
    boxShadow: 'none',
    '&:hover': {
      border: '0',
    },
  }),
};

// const ClearIndicator = (props) => (
//   <components.ClearIndicator {...props}>
//     <GreyX className="icon-auto-width" />
//   </components.ClearIndicator>
// );
const DropdownIndicator = (props) => (  
  <components.DropdownIndicator {...props}>
    <IconSort className="icon-auto-width" />
  </components.DropdownIndicator>
);

export { customStyles, DropdownIndicator}