const SvgIconAngleUp = (props) => (
  <svg viewBox="0 0 16 10" {...props}>
    <path
      d="M15.071 9L8 1.929.929 9"
      stroke="#000"
      strokeWidth={1.7}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgIconAngleUp;
